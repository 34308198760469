import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"

import { Container, Row, Col, Image } from "react-bootstrap"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Header from "../../../components/Header"
import Footer from "../../../components/Footer"

import { useGlobalState } from "../../../global/global"
import { getLanguageFile } from "../../../global/langauge.helper"
import { TextFormater } from "../../../global/textFormater"
import NGINR_IMG from "../../../../assets/images/products_nginr.png"

import "./styles.scss"

const NginrPage = () => {
  const [state] = useGlobalState()
  return (
    <Container fluid className="main-container">
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Nginr®</title>
        </Helmet>
      </div>
      <Header />
      <Container className="introduction-container ">
        <Row>
          <Col className="introduction-text-col col-lg-4 order-12 order-lg-1">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h3 className="introduction-text-heading">nginr</h3>
              <h6 className="copyright-text">®</h6>
            </div>
            <h5 className="introduction-text">
              {getLanguageFile(state.language).Nginr.nginrMainDescription}
            </h5>
          </Col>
          <Col className="introduction-img-col col-lg-8 col-12 order-1 order-lg-12">
            <Image src={NGINR_IMG} className="introduction_img" />
          </Col>
        </Row>
      </Container>
      <Container className="features-container">
        <p className="features-text">
          {getLanguageFile(state.language).Nginr.nginrDescriptionPartOne}
        </p>
        <p className="features-text">
          {getLanguageFile(state.language).Nginr.nginrDescriptionPartTwo}
        </p>
        <p className="features-text">
          {getLanguageFile(state.language).Nginr.nginrDescriptionPartThree}
        </p>
        <p className="features-text">
          <h4>
            <b>
              <center>
                {TextFormater(
                  "All-domain Information Fusion, Assessment and Coordination System"
                )}
              </center>
            </b>
          </h4>
          <p>
            {TextFormater(
              "All-domain (land, air, maritime, space and cyberspace) information fusion, assessment and coordination system made up of various components including the following:"
            )}
            <br />
            <ul>
              <li>
                {" "}
                {TextFormater(
                  "Engine for recognition and fusion (nginr<0>®</0>) is the core of the system. It is based on blocknetwork, which is our technology patented in many European, Eurasian countries and the US. In nginr<0>®</0>, anything that needs to be monitored is called an interest. Units, people, locations, equipment and incidents can become interests. A blockchain is created and maintained for each interest. The incoming intelligence, surveillance reconnaissance (ISR) and status report data files are processed to detect interests in them, and a block is created for each data file. A block is the list of interests in a data file, and if a block is related to more than one interest, it implies an interaction, and that block is owned by multiple blockchains. The system detects patterns and scenarios, recognizes incidents, and employs communications enabled services to follow the procedures and contingency plans. It is an all-domain ISR processing and fusion technology, which can also validate the accuracy of the fused information. "
                )}{" "}
              </li>
              <li>
                {" "}
                {TextFormater(
                  "All-domain common operational picture (COP) is a situational awareness system on which the statuses of interests are visualized in five dimensions (i.e., three spatial dimensions, time and interactions). COP can depict the blockchains of interests, hence not only the information about the latest status of interests but also their past data and interactions with other interests are depicted on a map. "
                )}
              </li>
              <li>
                {" "}
                {TextFormater(
                  "Multidomain assessment subsystem (MAS) is to analyze and assess if the decisive conditions to meet objectives are created. Decisive conditions are defined as scenarios made up of interactions of interests and the order of interactions. MAS autonomously decides if those decisive conditions are created based on the realization of scenarios. "
                )}
              </li>
              <li>
                {" "}
                {TextFormater(
                  "Multidomain rhythm subsystem (MRS) is to create a rhythm of activities required to manage and coordinate various all-domain processes. MRS is used as the collaborative process to coordinate and exchange the information products as the inputs and outputs of the activities of various processes. "
                )}
              </li>
              <li>
                {" "}
                {TextFormater(
                  "hymots<0>®</0> symbiotic hybrid multi-domain operations, tactics and strategy simulation system is a simulation tool that can work symbiotic to nginr<0>®</0>. It generates forecasts to predict the future incidents and potential outcomes of the contemporary setting based on geostrategic trends. It is connected to nginr<0>®</0> bi-directionally which means it provides the forecasts to nginr<0>®</0> and adapts the forecasts to the real setting as it changes in nginr<0>®</0>."
                )}
              </li>
              <br />
            </ul>
          </p>
          <h5 style={{ color: "black" }}>
            <b>
              {TextFormater("nginr<0>®</0> engine for recognition and fusion")}
            </b>
          </h5>
          <p>
            {" "}
            {TextFormater(
              "nginr<0>®</0> is the implementation of our patented technology called blocknetwork, which is a network of blockchains. The blockchain technology is accepted by many researchers as the breakthrough after the Internet. The Internet provides an efficient and universal way for people and machines to communicate. The blockchain technology complements the Internet with a simple but powerful trust mechanism. Trust on the accuracy of the original content and the preservation of the content integrity are critical requirements for many applications, and therefore, a blockchain can improve the processes that needs absolute trust in communications. A blockchain is a data structure and a set of algorithms for the decentralized validation of the information in the data structure.  "
            )}
          </p>{" "}
          <p>
            {TextFormater(
              "The blocknetwork scheme is a distributed scheme to gather information and fuse them into a knowledgebase. It is a network of blockchains. A blocknetwork behaves the same as a blockchain when there is only one chain in the network. Blocknetwork is designed to fuse information retrieved from big data, to create a NoSQL knowledgebase, and to store and manage the knowledgebase. This process has the following stages:"
            )}{" "}
            <br />
            <ul>
              <li>
                {" "}
                Process data and retrieve information: The information is
                retrieved from high variety of data, such as text, imageries,
                videos and signature data, when they are flowing in very large
                quantities.{" "}
              </li>
              <li>
                {" "}
                Analyze and fuse information into a NoSQL knowledgebase: A block
                is created for each data file. The body of the block is the list
                of interests in the data file. Then the block is linked to the
                blockchains of every interest in the block.{" "}
              </li>
              <li>
                {" "}
                Manage knowledgebase: The data files can be further processed
                later, which may relieve new information or necessitate
                correcting the previously retrieved information.{" "}
              </li>
              <li>
                {" "}
                Disseminate/share information/knowledge: The knowledge can be
                gained on surfing over the blocknetwork. For example, the
                blocknetwork can answer the queries, such as how an incident
                happened or what the relations among the interests are.
                Moreover, the system sends notifications following the rules
                defined by the system administrators, i.e., the conditions to
                trigger a notification and the receivers of the notification.
              </li>
              <br />
            </ul>
          </p>
          <h5 style={{ color: "black" }}>
            {" "}
            <b>{TextFormater("Common Operational Picture (COP)")}</b>
          </h5>
          <p>
            {TextFormater(
              "Live and/or batch data collected by millions of sensors, such as, the payloads of unmanned aerial vehicles, still or video cameras, surveillance cameras, radars, thermal cameras, manual entry terminals, data terminals for text data in various formats, can be linked to nginr<0>®</0>, which can process flowing data when they are incoming, and retrieve information, such as the location and time of interests (e.g., units, persons, vehicles, locations, objects, incidents and communications) autonomously, and establish temporal, spatial and relational facts about them in a knowledgebase."
            )}{" "}
          </p>
          <p>
            {TextFormater(
              "nginr<0>®</0> uses state of art deep machine learning techniques for image and video analytics, as well as natural language processing to recognize and identify vehicles, people, locations and incidents. In addition to that, our novel artificial intelligence techniques recognize and identify the military units, their formations and postures."
            )}
          </p>
          <p>
            {TextFormater(
              "COP can illustrate operational picture on a map or by using standard graphical representations. Although collected data can be as big as hundreds of petabytes even exabytes, the users can query the knowledge retrieved from the data very effectively. COP is an autonomous measurement, signature, open source, human, network intelligence (MASINT, SIGINT, OSINT, HUMINT, DNINT) fusion system for big data. "
            )}{" "}
          </p>
          <p>
            {TextFormater(
              "It depicts a common all-domain (land, air, maritime, space and cyber) operational picture with five dimensions (i.e., three spatial dimensions, time and interactions among the interests). The users can see graphically and query the whereabouts, status and interactions of interests. They can observe and learn the mobility and action patterns of interests on a graphical interface. For this, COP does not need human in the loop. By using artificial intelligence, it can associate and correlate the incoming raw data with units, persons, vessels, incidents, objects and even scenarios autonomously, and can engage with the scenarios as defined in a rule base, so called the engagement matrix."
            )}
          </p>
          <p>
            {TextFormater(
              "COP can be used as a network analysis tool. It can detect and analyze networks of interactions. Therefore, COP can analyze threat networks, such as, criminal or terrorist networks. Raw data from sensors such as pictures, videos, e-mails, social media interactions are processed by nginr<0>®</0>, and the information exchange (e.g., e-mails, social media communications) and interaction (e.g., being in the same picture/video, involving in the same incident, such as an accident, a demonstration, a homicide) networks are constructed. Connectivity degree, degree of separation, closeness, eigenvector (i.e., centrality) of the interests can be investigated in network analysis. COP can run in one of the following modes for this: "
            )}
          </p>
          <p>
            <b style={{ color: "black" }}>Realtime mode:</b>{" "}
            {TextFormater(
              "nginr<0>®</0> has interfaces to get connected to the real life sensors, such as, camera, Internet of Thing (IoT) devices. "
            )}{" "}
            <br />
            <b style={{ color: "black" }}>Simulation mode:</b>{" "}
            {TextFormater(
              "nginr<0>®</0> can be connected to hybrid multidomain operations, tactics and strategy simulation system (hymots<0>®</0>) for simulation purposes."
            )}{" "}
            <br />
            <b style={{ color: "black" }}>Symbiotic mode:</b> Symbiotic mode is
            the mix of real time and simulation modes. Simulation is used for
            making predictions about the future, which is corrected as the real
            time data are received and processed.
            <br />
            <br />
            {TextFormater(
              "COP can also manage the sharing of the knowledgebase with the other organizations and allies. "
            )}{" "}
            <br />
          </p>
          <h5 style={{ color: "black" }}>
            <b>{TextFormater("Multi-domain Assessment Subsystem (MAS)")}</b>
          </h5>
          <p>
            {TextFormater(
              "MAS analyzes and assesses whether the decisive conditions for the operational objectives are fulfilled, and the strategic objectives are met. The decisive conditions are created when the tasks related to them are completed and the scenarios defined for them are realized. As the decisive conditions are created, the operational and strategic objectives are met. MAS autonomously analyzes the blocknetwork to assess and provides the headquarters with visual aids to steer the strategic engagements and operations ultimately towards the political objectives. "
            )}{" "}
          </p>
          <h5 style={{ color: "black" }}>
            <b>{TextFormater("Multi-domain Rhythm Subsystem (MRS)")} </b>
          </h5>
          <p>
            {TextFormater(
              "MRS supports and connects the battlerhythm of multiple levels in a command hierarchy from strategic to tactical. It provides information products which are inputs to all-domain processes, such as operations planning, battlespace management, assessment, coordination, fires, targeting, personnel recovery. Information is tailored to the abstraction level that suits best to the supported process and echelon. It is also a collaborative working environment to synthesize and share knowledge among the functional areas and echelons."
            )}
          </p>
          <h5 style={{ color: "black" }}>
            <b>
              {TextFormater(
                "Hybrid Multi-domain Operations Tactics and Strategy Simulation System (hymots<0>®</0>)"
              )}{" "}
            </b>
          </h5>
          <p>
            {TextFormater(
              "Hybrid Multi-domain Operations Tactics and Strategy Simulation System(hymots<0>®</0>) has a module for geostrategic critical analysis called (hymots<0>®</0>g), which is an extensive form Bayesian geostrategic game with imperfect information."
            )}
          </p>
          <p>
            {TextFormater(
              "The main output of hymots<0>®</0>g is a world map on which all the state and none state actors are visualized. For the actors, values for 11 geostrategic metrics (political, diplomacy, information, military, economic, financial, intelligence, infrastructure, social, law enforcement and technology), 41 geostrategic parameters and various variables are visualized. The world map is colored according to the selected instrument or parameter values. In addition to that, as the conditions for the defined scenarios are met, warnings with various color codes appear in the screen. The users can query on those warnings.  "
            )}{" "}
          </p>
          <p>
            {TextFormater(
              "hymots<0>®</0>g is used for analyzing and assessing the consequences of the detected scenarios (i.e., incidents or crises) in short and medium term. When used together with nginr<0>®</0>, hymots<0>®</0>g also provides analysts with an insight into the relations of an incident with the other incidents, actors and locations and also compare them with the learned patterns. Each incident and actor have an associated blockchain in nginr<0>®</0>. When actors interact with each other or incidents, they share blocks with the blockchains dedicated to the interacted incidents and actors. These blockchains and their relations can be visualized on the nginr<0>®</0> COP which has five dimensions (three geographical dimensions, time and interactions)."
            )}
          </p>
          <p>
            {TextFormater(
              "Analysts can let hymots<0>®</0>g to select the strategies and geostrategic trends for the actors in geostrategic games, and they can interact with the game any time before or during simulation to change the selected strategies or to enter new strategies for a selected actor. Strategies have conditions to be fulfilled for being applicable. For example, a strategy may have an exclusive relation with a set of other strategies or may be required to be run together with a set of strategies. Strategies may also have constraints related to geostrategic metrics, parameters or variable values."
            )}
          </p>
          <p>
            {TextFormater(
              "hymots<0>®</0>g is for supporting strategic level processes including strategic foresight development, crises detection and warning, situation appreciation, response option development. It imitates a volatile, complex and ambiguous setting with limitless uncertainties for short and medium term (5-10 years) geostrategic foresight generation. A reasonable database preparation effort suffices to prepare it for complex simulation tasks. The system can reach up to 300/1 simulation speed within a global scenario. When it is symbiotic with nginr<0>®</0>, in other words with the reality, a digital twin of the real World is created to detect the crises patterns through simulation and incident pattern recognition. "
            )}
          </p>
          <p>
            {TextFormater(
              "For further information about the hybrid multi-domain operations tactical and strategic simulation for geostrategic analysis (hymots<0>®</0>g), please visit "
            )}

            <Link to={"https://www.hymots.info"} target="_blank">
              www.hymots.info
            </Link>
          </p>
        </p>
        <p className="features-text">
          {getLanguageFile("en").Nginr.nginrDescriptionPartFive}
        </p>
      </Container>
      <Footer />
    </Container>
  )
}

export default NginrPage
